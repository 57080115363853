<template>
    <div class="cloudPosPre">
        <div class="left-details">
            <div class="desk-box">
                <div class="title">
                    <svg class="iconfont icon-svg" aria-hidden="true"><use xlink:href="#icon-desk-svg"></use></svg>
                    <span class="nowrap flex-1">共{{(reserveDeskInfo?.Desk_Name||[]).length}}桌：{{(reserveDeskInfo?.Desk_Name||[]).join('、')}}</span>
                    <div class="right-bnt" v-show="reserveDeskInfo">
                        <i class="iconfont icon-dianhua2"></i>
                        <i class="iconfont icon-lock-closed-blue" @click="isLock=false" v-if="isLock"></i>
                        <i class="iconfont icon-jiesuo1" @click="isLock=true" v-else></i>
                    </div>
                </div>
                <div class="content-box">
                    <div class="flex-left" v-show="reserveDeskInfo">{{dingRptDate}}<span>/{{currentPhase?.EAT_PHASENAME}}</span></div>
                    <div class="flex-right" v-if="reserveDeskInfo?.Reserve_OrderNo">订单编号：{{reserveDeskInfo.Reserve_OrderNo}}</div>
                </div>
            </div>
            <div class="details-info">
                <div class="empty-box" v-if="!reserveDeskInfo">
                    <img src="/images/empty.png" />
                    <p class="empty-text">请选择桌台</p>
                </div>
                <div v-else>
                    <div class="tr-box">
                        <div class="lable-txt"><span class="red">*</span>客户电话：</div>
                        <div class="from-input"><input type="text" v-model="reserveDeskInfo.Mobile" placeholder="请输入电话"/></div>
                        <div class="bnt bnt-tal" @click="callHistoryShow=true">
                            <i class="iconfont icon-dianhua1"></i> 来电记录
                        </div>
                    </div>
                    <div class="tr-box">
                        <div class="lable-txt"><span class="red">*</span>客户姓名：</div>
                        <div class="from-input"><input type="text" v-model="reserveDeskInfo.Cstm_Name" placeholder="请输入姓名"/></div>
                        <el-radio-group v-model="reserveDeskInfo.Cstm_Sex" class="radio-group">
                            <el-radio :label="1">先生</el-radio>
                            <el-radio :label="2">女士</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="tr-box">
                        <div class="flex-1">
                            <div class="lable-txt"><span class="red">*</span>就餐人数：</div>
                            <div class="from-input"><input-pattern pattern="number" :dplaces="0" type="text" v-model="reserveDeskInfo.Reserve_PersonNum" placeholder="请输入人数"/></div>
                        </div>
                        <div class="flex-1">
                            <div class="lable-txt"><span class="red">*</span>到店时间：</div>
                            <div class="from-input">
                                <el-time-select class="select-time"
                                    placeholder="请选择时间"
                                    v-model="reserveDeskInfo.timeHM"
                                    start="00:00"
                                    step="00:30"
                                    end="23:59"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="tr-box">
                        <div class="flex-1">
                            <div class="lable-txt"><span class="red">*</span>预订类型：</div>
                            <div class="from-input">
                                <el-radio-group v-model="reserveDeskInfo.Itype" class="radio-group">
                                    <el-radio :label="1">普通</el-radio>
                                    <el-radio :label="2">宴会</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="flex-1">
                            <div class="lable-txt"><span class="red">*</span>订单渠道：</div>
                            <div class="from-input input-icon" @click="orderChanneShow=true">
                                <input type="text" v-model="reserveDeskInfo.channel_Name" placeholder="请选择" readonly/> <i class="iconfont icon-qudao"></i>
                            </div>
                        </div>
                    </div>
                    <div class="tr-box">
                        <div class="flex-1">
                            <div class="lable-txt"><span class="red">*</span>营&nbsp; 销 &nbsp;员：</div>
                            <div class="from-input input-icon" @click="waiSaleShow=true">
                                <input type="text" v-model="reserveDeskInfo.Reserve_Operator" placeholder="请选择" readonly/> <i class="iconfont icon-kehu"></i>
                            </div>
                        </div>
                        <div class="flex-1">
                            <div class="lable-txt"><span class="red">*</span>消费类型：</div>
                            <div class="from-input input-icon" @click="consumeTypeShow=true">
                                <input type="text" v-model="reserveDeskInfo.Eat_XfTypeName" placeholder="请选择" readonly/> <i class="iconfont icon-xiaofeileixing"></i>
                            </div>
                        </div>
                    </div>
                    <div class="tr-box">
                        <div class="flex-1">
                            <div class="lable-txt">短信通知：</div>
                            <div class="from-input">
                                <el-radio-group v-model="smsRadio" class="radio-group">
                                    <el-radio :label="1">不发送</el-radio>
                                    <el-radio :label="2">发送</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="flex-1">
                            <div class="lable-txt">短信模板：</div>
                            <div class="from-input">
                                <el-select v-model="smsMould">
                                    <el-option :value="item.Autoid" :label="item.ModuleName" v-for="item in cstmModuleList" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="tr-box">
                        <div class="lable-txt">是否配菜：</div>
                        <el-switch v-model="IS_sideDish" inline-prompt active-text="是" inactive-text="否"></el-switch>
                        <div class="bnt2">去配菜</div>
                    </div>
                    <div class="tr-box">
                        <div class="lable-txt">配菜金额：</div>
                        <div class="from-input"><input type="text" /></div>
                        <el-select class="unit-select" v-model="unit">
                            <el-option :value="0" label="元/人"></el-option>
                            <el-option :value="1" label="元/桌"></el-option>
                        </el-select>
                    </div>
                    <div class="tr-box">
                        <div class="lable-txt">是否定金：</div>
                        <el-switch v-model="IS_sideDish" inline-prompt active-text="是" inactive-text="否"></el-switch>
                        <div class="bnt2" @click="collectDepositShow=true">收取定金</div>
                    </div>
                    <div class="tr-box">
                        <div class="lable-txt">客户忌口：</div>
                        <div class="flex-box">
                            <div class="flex-1">
                                <div class="from-input"><input type="text" v-model="reserveDeskInfo.Cstm_NoLike" placeholder="可输入内容"/></div>
                                <div class="bnt bnt-tal">
                                    <i class="iconfont icon-plus-circle"></i> 添加标签
                                </div>
                            </div>
                            <div class="tag-box">
                                <span class="tag" v-for="item in 8" :key="item">默认标签</span>
                                <span class="tag-more">更多<i class="iconfont icon-a-order-down"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="tr-box">
                        <div class="lable-txt">客户喜好：</div>
                        <div class="flex-box">
                            <div class="flex-1">
                                <div class="from-input"><input type="text" v-model="reserveDeskInfo.Cstm_Like" placeholder="可输入内容"/></div>
                                <div class="bnt bnt-tal">
                                    <i class="iconfont icon-plus-circle"></i> 添加标签
                                </div>
                            </div>
                            <div class="tag-box">
                                <span class="tag" v-for="item in 8" :key="item">默认标签</span>
                                <span class="tag-more">更多<i class="iconfont icon-a-order-down"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="tr-box">
                        <div class="lable-txt">备 &nbsp; &nbsp; &nbsp; 注：</div>
                        <div class="from-input"><input type="text" v-model="reserveDeskInfo.Reserve_Remark" placeholder="输入内容请不要超过100个汉字"/></div>
                    </div>
                </div>
            </div>
            <div class="foot-bnt" >
                <div class="more-bnt" @click="moreShow=!moreShow"><svg class="iconfont icon-svg" aria-hidden="true"><use xlink:href="#icon-gengduogongneng"></use></svg></div>
                <div class="flex-1"></div>
                <div class="bnt bnt-cancel" v-if="reserveDeskInfo" @click="cancelClick()">放弃</div>
                <div class="bnt bnt-save" v-if="reserveDeskInfo" @click="saveReserve()">保存预订</div>
            </div>
            <!-- 更多 -->
            <div class="foot-more-box" v-show="moreShow">
                <div class="title">
                    更多功能 <i class="close-bnt iconfont icon-guanbi" @click="moreShow=false"></i>
                </div>
                <div class="content-box">
                    <div class="li-bnt" @click="goCustom()">
                        <svg class="iconfont icon-svg" aria-hidden="true"><use xlink:href="#icon-zhongyaokehu"></use></svg>
                        <p>新增客户</p>
                    </div>
                    <div class="li-bnt">
                        <svg class="iconfont icon-svg" aria-hidden="true"><use xlink:href="#icon-huanzhuo"></use></svg>
                        <p>新增客户</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-eatrooms" :style="{width:layout.deskWidth}">
            <div class="filter-box">
                <div class="flex-box">
                    <div class="today-bnt" @click="businessHours=toDay">今日</div>
                    <lunar-calendar class="from-date"
                        v-model="businessHours"
                        :minDate="new Date()"
                        type="date"
                        placeholder=""/>
                    
                    <el-dropdown trigger="click" class="dropdown-box">
                        <div class="dropdown-flex">
                            <i class="iconfont icon-B-shijian"></i>
                            <div class="dropdown-text nowrap">
                                <template v-if="currentPhase">
                                    {{currentPhase?.EAT_PHASENAME}}
                                </template>
                                <template v-else>
                                    <span class="dropdown__placeholder">请选择时段</span>
                                </template>
                            </div>
                            <i class="iconfont icon-a-order-down"></i>
                        </div>
                        <template #dropdown>
                            <el-dropdown-menu class="dropdown-popper">
                                <el-dropdown-item :class="{selected:currentPhase?.EAT_PHASEID==item.EAT_PHASEID}" v-for="item in eatPhases" :key="item" @click="currentPhase=item">
                                    <div class="li">{{item.EAT_PHASENAME}}</div><i class="iconfont icon-gou-fine"></i>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <el-dropdown trigger="click" class="dropdown-box">
                        <div class="dropdown-flex">
                            <i class="iconfont icon-zhuangtai"></i>
                            <div class="dropdown-text nowrap">
                                {{(["全部状态","空闲","预订","入座","锁台","有定金","已超时","电话未确认","电话已确认"])[currentStatus]}}
                            </div>
                            <i class="iconfont icon-a-order-down"></i>
                        </div>
                        <template #dropdown>
                            <el-dropdown-menu class="dropdown-popper">
                                <el-dropdown-item @click="currentStatus=0" :class="{selected:currentStatus==0}">
                                    <div class="li"><i class="icon icon-all"></i>全部状态</div><i class="iconfont icon-gou-fine"></i>
                                </el-dropdown-item>
                                <el-dropdown-item @click="currentStatus=1" :class="{selected:currentStatus==1}">
                                    <div class="li"><i class="icon icon-empty"></i>空闲</div><i class="iconfont icon-gou-fine"></i>
                                </el-dropdown-item>
                                <el-dropdown-item @click="currentStatus=2" :class="{selected:currentStatus==2}">
                                    <div class="li"><i class="icon icon-preBook"></i>预订</div><i class="iconfont icon-gou-fine"></i>
                                </el-dropdown-item>
                                <el-dropdown-item @click="currentStatus=3" :class="{selected:currentStatus==3}">
                                    <div class="li"><i class="icon icon-seating"></i>入座</div><i class="iconfont icon-gou-fine"></i>
                                </el-dropdown-item>
                                <el-dropdown-item @click="currentStatus=4" :class="{selected:currentStatus==4}">
                                    <div class="li"><i class="icon icon-lock"></i>锁台</div><i class="iconfont icon-gou-fine"></i>
                                </el-dropdown-item>
                                <el-dropdown-item @click="currentStatus=5" :class="{selected:currentStatus==5}">
                                    <div class="li"><svg class="iconfont icon-svg" aria-hidden="true"><use xlink:href="#icon-dingjin"></use></svg>有定金</div><i class="iconfont icon-gou-fine"></i>
                                </el-dropdown-item>
                                <el-dropdown-item @click="currentStatus=6" :class="{selected:currentStatus==6}">
                                    <div class="li"><i class="iconfont icon-jinggao1"></i>已超时</div><i class="iconfont icon-gou-fine"></i>
                                </el-dropdown-item>
                                <el-dropdown-item @click="currentStatus=7" :class="{selected:currentStatus==7}">
                                    <div class="li"><i class="icon icon-unidentified"></i>电话未确认</div><i class="iconfont icon-gou-fine"></i>
                                </el-dropdown-item>
                                <el-dropdown-item @click="currentStatus=8" :class="{selected:currentStatus==8}">
                                    <div class="li"><i class="iconfont icon-dianhua1"></i>电话已确认</div><i class="iconfont icon-gou-fine"></i>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <el-dropdown trigger="click" class="dropdown-box">
                        <div class="dropdown-flex">
                            <i class="iconfont icon-quanbu"></i>
                            <div class="dropdown-text nowrap">
                                <template v-if="consumeType">
                                    {{consumeType?.ConsumeType_Name}}
                                </template>
                                <template v-else>
                                    全部类型
                                </template>
                            </div>
                            <i class="iconfont icon-a-order-down"></i>
                        </div>
                        <template #dropdown>
                            <el-dropdown-menu class="dropdown-popper">
                                <el-dropdown-item @click="consumeType=null" :class="{selected:consumeType==null}">
                                    <div class="li">全部类型</div><i class="iconfont icon-gou-fine"></i>
                                </el-dropdown-item>
                                <el-dropdown-item :class="{selected:consumeType?.ConsumeType_AutoID==item.ConsumeType_AutoID}" v-for="item in consumptionList" :key="item" @click="consumeType=item">
                                    <div class="li">{{item.ConsumeType_Name}}</div><i class="iconfont icon-gou-fine"></i>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
                <div class="refresh-bnt" @click="loadDeskMap()"><i class="iconfont icon-a-shuaxin3x"></i>刷新</div>
            </div>
            <div class="filter-nav">
                <div class="nav-box" v-mouse-scroll v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'DeskNextPage',isNavFirstSticky:true}">
                    <div class="nav-li" :class="{ selected: filternav == -1 }"  @click="filternav=-1" >全部</div>
                    <div class="nav-li" :class="{ selected: filternav == index }" 
                        v-for="(item,index) in posRooms" :key="item"  
                        @click="filternav=index" >
                    {{item.Room_Name}}
                    </div>
                </div>
                <div class="page-box">
                    <span class="last-page iconfont icon-zuo" ref="nav-up" ></span>
                    <span class="page-number">{{DeskNextPage.pageIndex}}/{{DeskNextPage.pageSize}} 页</span>
                    <span class="next-page iconfont icon-you" ref="nav-down" ></span>
                </div>
            </div>
            <div class="desk-list-box">
                <layout-rc class="item-box clearfix" :row="layout.row" :col="layout.col" :marginW="4" :marginH="4" :isVarCss="true">
                    <div class="item-li" :class="{selected:selectDeskKeys[item.Desk_AutoID]}" rc-item v-for="item in posDesksPage" :key="item" @click="deskClick(item)">
                        <div class="top-describe" v-if="item.ReserveCstmDeskInfo">{{dingDescribe(item)}}</div>
                        <div class="name">{{item.Desk_Name}}</div>
                        <div class="num"><span v-if="item.ReserveDeskInfo">{{item.ReserveDeskInfo.Reserve_PersonNum}}/</span>{{item.Seat_Numbers}}人</div>
                        <div class="tag-box">
                            <div class="left-box">
                                <span class="pei-tag" v-if="item.ReserveDeskInfo?.ReserveOrderConsumes?.length>0">配</span>
                                <svg class="iconfont icon-svg" aria-hidden="true" v-if="false"><use xlink:href="#icon-dingjin"></use></svg>
                            </div>
                            <span class="status-tag lock-tag" v-if="item.Is_Desk_Locked">锁台</span>
                            <span class="status-tag idle-tag" v-else-if="item.Desk_Status==0">空闲</span>
                            <span class="status-tag reservation-tag" v-else-if="item.Desk_Status==2">预订</span>
                            <span class="status-tag seating-tag" v-else-if="item.Desk_Status==1">入座</span>
                            <div class="right-box">
                                <i class="iconfont icon-jinggao1" v-if="item.Desk_Status==4"></i>
                            </div>
                        </div>
                        <div class="top-right-type">
                            <i class="iconfont icon-dianhua1" v-if="false"></i>
                            <i class="iconfont icon-lock-closed-blue" v-if="item.Is_Desk_Locked"></i>
                        </div>
                        <i class="iconfont icon-xuanzejiaobiao"></i>
                    </div>
                </layout-rc>
            </div>
            <div class="foot-box">
                <key-board-input class="search"
                    type="text" v-model="searchText" placeholder="按桌号检索"
                    isKeyEnterClose isBlurClose
                ></key-board-input>
                <div class="deskType-box">
                    <span class="deskType">全部:{{deskStatusTotal.Desk_Numbers}}</span> 
                    <span class="deskType">空闲:{{deskStatusTotal.freePer}}({{deskStatusTotal.Free_Desks}})</span> 
                    <span class="deskType">预订:{{deskStatusTotal.reservePer}}({{deskStatusTotal.Reserve_Desks}})</span> 
                    <span class="deskType">在用:{{deskStatusTotal.openedPer}}({{deskStatusTotal.Opened_Desks}})</span>
                </div>
                <div class="page-box">
                    <page-turning class="last-page" type="up" v-model="pageIndex" :pageCount="deskPageCount"><i class="iconfont icon-zuo"></i></page-turning>
                    <span class="page-number">{{pageIndex}}/{{deskPageCount}} 页</span>
                    <page-turning class="next-page" v-model="pageIndex" :pageCount="deskPageCount"><i class="iconfont icon-you"></i></page-turning>
                </div>
            </div>
        </div>

        <!--营销员-->
        <modal-load :isShow="waiSaleShow">
            <waiter-Sales-Model title="营销员" :isShow="waiSaleShow" @closeModel="waiSaleShow=false" :SalesList="salesList" :waiSaleType="2" @waiSaleConfirm="waiSaleConfirm" />
        </modal-load>
        <!--订单渠道-->
        <modal-load :isShow="orderChanneShow">
            <order-Channel-Model title="客户渠道" :isShow="orderChanneShow" @closeModel="orderChanneShow=false" 
                :Openorderclass="reserveDeskInfo?.Channel_ID" :OrderList="channelList" :orderChannel="channelNav" 
                @orderChanneConfirm="orderChanneConfirm" />
        </modal-load>
        <!--消费类型-->
        <modal-load :isShow="consumeTypeShow">
            <consume-Types-Model :isShow="consumeTypeShow" @closeModel="consumeTypeShow=false" 
            :consumeTypes="consumeTypes" :ConsumptionList="consumptionList" @consumeTypeConfirm="consumeTypeConfirm"/>
        </modal-load>
        <!-- 收取定金 -->
        <modal-load :isShow="collectDepositShow">
            <collect-deposit-model :isShow="collectDepositShow" @close="collectDepositShow=false" />
        </modal-load>
        <!-- 来电记录 -->
        <modal-load :isShow="callHistoryShow">
            <call-history-model :isShow="callHistoryShow" @close="callHistoryShow=false" />
        </modal-load>
    </div>
</template>

<script>
import waiterSalesModel from '../diningTable/model/waiterSalesModel/waiterSalesModel.vue'
import orderChannelModel from '../diningTable/model/orderChannelModel/orderChannelModel.vue'
import consumeTypesModel from '../diningTable/model/consumeTypesModel/consumeTypesModel.vue'
import collectDepositModel from './collectDepositModel/collectDepositModel.vue'
import callHistoryModel from './callHistoryModel/callHistoryModel.vue'

export default {
    components:{
        waiterSalesModel,
        orderChannelModel,
        consumeTypesModel,
        collectDepositModel,
        callHistoryModel
    },
    name:"cloudPosPre",
    data(){
        return {
            toDay:(new Date()).Format('yyyy-MM-dd'),
            businessHours:new Date(),
            //时段
            eatPhases:null,
            //选中的时段
            currentPhase:null,
            //筛选条件 预定消费类型
            consumeType:null,
            //状态  默认 全部状态
            currentStatus:0,
            /**餐厅页码 */
            DeskNextPage:{
                pageIndex:1,
                pageSize:1
            },
            //桌台数据
            reserveDeskMap:null,
            //桌台分类 默认-1 全部
            filternav:-1,
            //桌台检索 
            searchText:"",
            //桌台当前页
            pageIndex:1,
            //选中的桌台
            selectDesk:[],
            //是否锁
            isLock:false,
            //短信通知
            smsRadio:1,
            //短信模板
            smsMould:"",
            //是否定金
            IS_sideDish:false,
            //配菜金额
            unit:0,
            //更多按钮
            moreShow:false,
            //来电记录 弹层
            callHistoryShow:false,
            //收取定金 弹层
            collectDepositShow:false,
            //短信模版数据
            cstmModuleList:[],
            //营销员 弹层
            waiSaleShow:false,
            //营销员数据
            salesList:[],
            //订单渠道 弹层
            orderChanneShow:false,
            //渠道数据
            channelList:[],
            //渠道分类
            channelNav:[],
            
            //消费类型 弹层
            consumeTypeShow:false,
            //消费类型分类
            consumeTypes:[],
            //消费类型数据
            consumptionList:[],
            
            //预定订单信息
            reserveDeskInfo:null
        }
    },
    computed:{
        /**桌台数据 */
        posRooms(){
            return this.reserveDeskMap?.PosRooms||[];
        },
        /**全部桌台数据 */
        allDesk(){
            let _allDesk=[];
            (this.posRooms||[]).forEach((room)=>{
                _allDesk=_allDesk.concat(room.PosDesks);
            })
            return _allDesk;
        },
        /**桌台状态合计 */
        deskStatusTotal(){
            let total={
                Desk_Numbers:0,//桌台总数
                Free_Desks:0,//空闲桌台数量
                Opened_Desks:0,//开台桌台数量
                Reserve_Desks:0,//预订桌台数量
            };
            this.allDesk?.forEach(it=>{
                total.Desk_Numbers++;
                if(it.Desk_Status==0){//空闲
                    total.Free_Desks++;
                }else if(it.Desk_Status==1){//入座
                    total.Opened_Desks++;
                }else if(it.Desk_Status==2){//预订
                    total.Reserve_Desks++;
                }
            })

            total.freePer=total.Desk_Numbers==0?"0%":(Number((total.Free_Desks / total.Desk_Numbers*100).toFixed(2))+"%");
            total.openedPer=total.Desk_Numbers==0?"0%":(Number((total.Opened_Desks / total.Desk_Numbers*100).toFixed(2))+"%");
            total.reservePer=total.Desk_Numbers==0?"0%":(Number((total.Reserve_Desks / total.Desk_Numbers*100).toFixed(2))+"%");
            return total;
        },
        //筛选
        posDesksFilter(){
            let posDesks= this.allDesk;
            if(this.filternav>=0){
                posDesks=this.posRooms[this.filternav].PosDesks
            }

            if(this.currentStatus==1){//空闲
                posDesks=posDesks.filter(it=>it.Desk_Status==0);
            }else if(this.currentStatus==2){//预订
                posDesks=posDesks.filter(it=>it.Desk_Status==2);
            }else if(this.currentStatus==3){//入座
                posDesks=posDesks.filter(it=>it.Desk_Status==1);
            }else if(this.currentStatus==4){//锁台
                posDesks=posDesks.filter(it=>it.Is_Desk_Locked);
            }else if(this.currentStatus==5){//有定金
                //posDesks=posDesks.filter(it=>it.Desk_Status==1);
            }else if(this.currentStatus==6){//已超时
                //posDesks=posDesks.filter(it=>it.Desk_Status==1);
            }else if(this.currentStatus==7){//电话未确认
                //posDesks=posDesks.filter(it=>it.Desk_Status==1);
            }else if(this.currentStatus==8){//电话已确认
                //posDesks=posDesks.filter(it=>it.Desk_Status==1);
            }

            if(this.consumeType){//预定消费类型
                posDesks=posDesks.filter(it=>it.Desk_Status==2 && it.ReserveDeskInfo?.Eat_XfTypeID==this.consumeType?.ConsumeType_AutoID);
            }

            let text=(this.searchText||"").trim().toLowerCase()
            if(text){
                //检索
                posDesks=posDesks.filter(item=>(item.Desk_Code||"").toLowerCase().indexOf(text)>=0 || (item.Desk_Name||"").toLowerCase().indexOf(text)>=0)
            }
            return posDesks;
        },
        /**桌台分页数据 */
        posDesksPage(){
            return this.posDesksFilter.slice((this.pageIndex-1)*this.deskPageSize,this.pageIndex * this.deskPageSize);
        },
        //配置
        globalSysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting
        },
        //一页 多少条
        deskPageSize(){
            return this.layout.row*this.layout.col;
        },
        //总页数
        deskPageCount(){
            return Math.ceil(this.posDesksFilter.length/this.deskPageSize);
        },
        //布局 配置
        layout(){
            let data={
                deskWidth:61+"%",
                row:6,//行
                col:5,//列
            }
            return data;
        },
        selectDeskKeys(){
            let keys={};
            this.selectDesk.forEach(it=>{
                keys[it.Desk_AutoID]=it;
            })
            return keys;
        },
        //预定日期显示
        dingRptDate(){
            if(this.businessHours){
                let day=(new Date(this.businessHours)).getDay();
                return this.businessHours+" 星期"+("日一二三四五六")[day];
            }
            return "";
        }
    },
    watch:{
        //时段变动时
        currentPhase(){
            if(this.currentPhase?.EAT_PHASEID && this.businessHours){
                this.loadDeskMap();
            }
        },
        //预订日期变动
        businessHours(){
            if(this.currentPhase?.EAT_PHASEID && this.businessHours){
                this.loadDeskMap();
            }
        }
    },
    mounted(){
        this.$emit("navIndex",5);
        /**基础配置 */
        this.$cacheData.DeskPosconfigure().then((d)=>{
            console.log(d)
            
        }).catch(()=>{
            console.log("获取基础配置失败")
        })
        
        this.$cacheData.EatPhase().then((d)=>{
            if(d){
                this.eatPhases=d;
                if(d.length>0){
                    this.currentPhase=d[0];
                }
            }
        }).catch((e)=>{
            this.$message.error('加载时段数据失败：'+e,);
            console.log('加载时段数据失败：'+e);
        })
        /**订单渠道 */
        this.$cacheData.OrderChannel().then((d)=>{
            this.channelNav = d;
            let list=[]
            d?.forEach(order=>{
                order.OrderChannelList.forEach(item=>{
                    Object.assign({},item, {is_type:0,Kind_AutoID:order.OrderChannelKind_AutoID})
                    list.push(item)
                })
            })
            this.channelList=list;
        }).catch(()=>{
            this.$alert('暂无订单渠道', "提示", {confirmButtonText: "确定"});
        })
        /**消费类型*/
        this.$cacheData.ConsumeTypes().then((d)=>{
            this.consumeTypes=d;
            let list=[];
            d?.forEach(consu=>{
                consu.ConsumeTypeList.forEach(item=>{
                    Object.assign({},item, {is_type:0,Kind_AutoID:consu.ConsumeTypeKind_AutoID})
                    list.push(item)
                })
            })
            this.consumptionList=list;
        }).catch(()=>{
            this.$alert('暂无消费类型', "提示", {confirmButtonText: "确定"});
        })

        /**销售员*/
        this.$cacheData.PosSellers().then((d)=>{
            this.salesList=d;
        }).catch(()=>{
            this.$alert('暂无销售员', "提示", {confirmButtonText: "确定"});
        })

        /*短信模版*/
        this.$cacheData.CstmModuleInfo().then(d=>{
            this.cstmModuleList=d;
        }).catch(err=>{
            console.log("短信模版获取失败",err)
        })

        
    },
    methods:{
        /**预定信息显示 */
        dingDescribe(item){
            //张先生(0586) 17:30
            let text=item.ReserveCstmDeskInfo.Cstm_Name;
            if(item.ReserveCstmDeskInfo.Mobile){//手机号取后四位
                let mobile=item.ReserveCstmDeskInfo.Mobile;
                text+="("+mobile.substring(mobile.length-4)+")";
            }
            text+=(new Date(item.ReserveCstmDeskInfo.Reserve_Time)).Format("hh:mm");
            return text;
        },
        //点击桌台
        deskClick(item){
            let index= this.selectDesk.findIndex(it=>it.Desk_AutoID==item.Desk_AutoID);
            if(index>=0){
                this.selectDesk.splice(index,1);
            }else{
                if(item.ReserveDeskInfo){//已经预定的 预定数据
                    this.selectDesk=[item];
                }else{
                    if(this.selectDesk.some(it=>it.ReserveDeskInfo)){//有预定数据的
                        this.selectDesk=[];//清空
                        this.reserveDeskInfo=null;
                    }
                    this.selectDesk.push(item);
                }
            }

            if(this.selectDesk.length>0){
                if(this.selectDesk.length==1 && this.selectDesk[0].ReserveDeskInfo){//已经预定的 预定数据
                    this.reserveDeskInfo=this.defaultReserveData(this.selectDesk[0].ReserveDeskInfo);
                }else{//
                    if(!this.reserveDeskInfo){
                        this.reserveDeskInfo=this.defaultReserveData();
                    }
                    //预定桌台
                    this.reserveDeskInfo.Desk_Name=this.selectDesk.map(it=>it.Desk_Name);
                }
            }else{
                this.reserveDeskInfo=null;
            }
        },
        /**默认预订数据 */
        defaultReserveData(data){
            let info={
                Cstm_Name:"",//姓名
                Cstm_Sex:1,//性别
                Mobile:"",//手机号
                Reserve_OperatorID:"",//销售员ID
                Reserve_Operator:"",//销售员
                Reserve_Remark:"",//预订单备注
                Reserve_PersonNum:1,//预订人数
                Reserve_Time:this.businessHours,//预订时间
                timeHM:"",//预订时间 11:30
                Itype:1,//预定类型
                Channel_ID:"",//预定渠道ID
                channel_Name:'',
                Eat_XfTypeID:"",//消费类型
                Eat_XfTypeName:"",
                Cstm_Like:"",//客户喜好
                Cstm_NoLike:"",//客户忌口
            }
            Object.assign(info,data);
            if(data?.Reserve_Time){
                info.timeHM=(new Date(data?.Reserve_Time)).Format("hh:mm");
            }
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            if(info.Channel_ID){//预定渠道ID
                let channer=this.channelList?.find(it=>it.OrderChannel_AutoID==info.Channel_ID);
                if(channer){
                    info.channel_Name=channer.OrderChannel_Name;
                }
            }else{
                let channer=this.channelList?.find(it=>it.OrderChannel_AutoID==userInfo?.Default_ChannelID);
                if(channer){
                    info.Channel_ID=channer.OrderChannel_AutoID
                    info.channel_Name=channer.OrderChannel_Name;
                }
            }
            if(info.Eat_XfTypeID){//消费类型
                let item=this.consumptionList?.find(it=>it.ConsumeType_AutoID==info.Eat_XfTypeID);
                if(item){
                    info.Eat_XfTypeID = item.ConsumeType_AutoID;
                    info.Eat_XfTypeName = item.ConsumeType_Name;
                }
            }
            return info;
        },
        /**选择渠道 */
        orderChanneConfirm(item){
            this.reserveDeskInfo.Channel_ID=item.OrderChannel_AutoID;
            this.reserveDeskInfo.channel_Name=item.OrderChannel_Name;
            this.orderChanneShow =false
        },
        /**消费类型返回*/
        consumeTypeConfirm(item){
            this.reserveDeskInfo.Eat_XfTypeID = item.ConsumeType_AutoID;
            this.reserveDeskInfo.Eat_XfTypeName = item.ConsumeType_Name;
            this.consumeTypeShow =false
        },
        /**营销员返回 */
        waiSaleConfirm(seleUser){
            this.reserveDeskInfo.Reserve_OperatorID = seleUser.User_ID;
            this.reserveDeskInfo.Reserve_Operator = seleUser.User_Name;
            this.waiSaleShow=false
        },
        /**客户管理界面 */
        goCustom(){
            this.$router.push("customManage"); 
        },
        /**加载桌台数据 */
        loadDeskMap(){
            if(!this.businessHours){
                this.$message.warning('请先选择营业日期');
                return;
            }
            if(!this.currentPhase?.EAT_PHASEID){
                this.$message.warning('请先选择时段');
                return;
            }
            let loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let param={
                Rpt_Date: this.businessHours,
                Phase_ID: this.currentPhase?.EAT_PHASEID
            }
            console.log(param)
            return this.$httpAES.post("Bestech.CloudPos.GetReserveDeskMap", param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.reserveDeskMap=d.ResponseBody;
                    console.log(d.ResponseBody)
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**保存预订 */
        saveReserve(){
            if(!this.reserveDeskInfo){
                this.$message.warning('请选择需要预订的桌台！');
                return;
            }

            if(!this.reserveDeskInfo.Mobile?.trim()){
                this.$message.warning('请输入客户电话！');
                return;
            }

            if(!this.reserveDeskInfo.Cstm_Name?.trim()){
                this.$message.warning('请输入客户姓名！');
                return;
            }
            if(!(this.reserveDeskInfo.Reserve_PersonNum>0)){
                this.$message.warning('就餐人数必须大于0！');
                return;
            }
            if(!this.reserveDeskInfo.timeHM){
                this.$message.warning('请选择到店时间！');
                return;
            }
            if(!this.reserveDeskInfo.Itype){
                this.$message.warning('请选择预订类型！');
                return;
            }
            if(!this.reserveDeskInfo.Channel_ID){
                this.$message.warning('请选择订单渠道！');
                return;
            }
            if(!this.reserveDeskInfo.Eat_XfTypeID){
                this.$message.warning('请选择消费类型！');
                return;
            }
            if(!this.reserveDeskInfo.Reserve_OperatorID){
                this.$message.warning('请选择营销员！');
                return;
            }

            let loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                Operator_Name:userInfo?.Login_Name,
                Eat_DeskID:this.selectDesk.map(it=>it.Desk_AutoID),//桌台id 多个
                Eat_PreDate: this.businessHours,//预订日期
                Eat_PhaseID: this.currentPhase?.EAT_PHASEID,//预定时段
                ReservePreInfo:this.reserveDeskInfo,//预定信息
            }
            //预订时间
            param.ReservePreInfo.Reserve_Time=this.businessHours+" "+param.ReservePreInfo.timeHM+":00";
            console.log(param)
            return this.$httpAES.post("Bestech.CloudPos.SaveReservePreInfo", param).then((d)=>{
                console.log("保存预订 返回结果",d);
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.emptyReserve();
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("保存预订失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**取消预订 */
        cancelReserve(id){
            let loading = this.$loading({
                text: "取消预订中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                Operator_Name:userInfo?.Login_Name,
                Reserve_AutoID :id,//预订单ID
            }
            console.log(param)
            return this.$httpAES.post("Bestech.CloudPos.CancelReservePreInfo", param).then((d)=>{
                console.log("取消预订中 返回结果",d);
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.emptyReserve();
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("取消预订失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**取消预订 */
        cancelClick(){
            if(this.reserveDeskInfo?.Reserve_AutoID){
                this.$confirm("是否确认取消预订", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText:"取消",
                    callback: (name) => {
                        if(name=='confirm'){
                            this.cancelReserve(this.reserveDeskInfo.Reserve_AutoID);
                        }
                    },
                });
            }else{
                this.emptyReserve();
            }
        },
        /**清空预订数据 */
        emptyReserve(){
            this.reserveDeskInfo=null;//清空数据
            this.selectDesk=[];
        }
    }
}
</script>

<style lang="scss">
	@import './cloudPosPre.scss'
</style>
